import { Link } from "react-router-dom";

// Components
import Layout from "../components/Layout";

// Icons
import { VscChevronRight } from "react-icons/vsc";

const items = [
  { title: "Razor", price: 20 },
  { title: "Toothbrush", price: 10 },
  { title: "Toothpaste", price: 20 },
  { title: "Spoon & fork (per set)", price: 5 },
  { title: "Plastic bowl", price: 5 },
  { title: "Sanitary pad", price: 20 },
];

const MiniStorePage = () => {
  return (
    <Layout title="Mini Store">
      <div className="px-4 pb-8 max-w-3xl mx-auto bg-[#fafafa] min-h-screen">
        {/* Apply to all pages */}
        <div className="text-sm py-3 text-neutral-600 flex gap-1 items-center">
          <Link to="/">
            <p className="hover:underline hover:text-deepOrange-700">Home</p>
          </Link>
          <VscChevronRight />
          <p className="font-medium">Mini Store</p>
        </div>
        {/* Ends here */}
        <header className="mt-5">
          <h1 className="text-2xl font-medium text-neutral-700">Mini Store</h1>
          <p className="mt-1 text-neutral-600">
            Our on-site convenience store offers a selection of essential items.
            It's a convenient stop for any last-minute needs.
          </p>
        </header>
        <main className="mt-6">
          <table className="w-full">
            <thead>
              <tr className="bg-neutral-200">
                <th className="text-deepOrange-700 font-medium text-left p-2">
                  Item
                </th>
                <th className="text-sm text-deepOrange-700 font-medium text-right p-2">
                  Price (in Thai Baht)
                </th>
              </tr>
            </thead>
            <tbody className="text-neutral-600">
              {items.map((item, index) => (
                <tr
                  key={item.title}
                  className={`${index % 2 === 0 ? "" : "bg-neutral-200"}`}
                >
                  <td className="p-2">{item.title}</td>
                  <td className="text-right p-2">{item.price}.-</td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="mt-6">
            <p className="text-deepOrange-700 ">
              * Please contact the front office to purchase these items.
            </p>
          </div>
        </main>
      </div>
    </Layout>
  );
};

export default MiniStorePage;
