import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

// React-router-dom
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";

// Pages
import HomePage from "./routes/HomePage";
import HotelDirectoryPage from "./routes/HotelDirectoryPage";
import FacilitiesPage from "./routes/FacilitiesPage";
import RoomServicePage from "./routes/RoomServicePage";
import MiniStorePage from "./routes/MiniStorePage";
import ErrorPage from "./routes/ErrorPage";
import GuestQuestionnairePage from "./routes/GuestQuestionnairePage";

// Components
import Navbar from "./components/Navbar";

const NavbarWrapper = () => {
  return (
    <div>
      <Navbar />
      <Outlet />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <NavbarWrapper />,
    errorElement: (
      <div className="bg-[#fafafa]">
        <Navbar />
        <ErrorPage />
      </div>
    ),
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      { path: "/hotel-directory", element: <HotelDirectoryPage /> },
      { path: "/facilities", element: <FacilitiesPage /> },
      { path: "/room-service", element: <RoomServicePage /> },
      { path: "/mini-store", element: <MiniStorePage /> },
      { path: "/guest-questionnaire", element: <GuestQuestionnairePage /> },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
