// Component
import Layout from "../components/Layout";

// Icons
import { VscChevronRight } from "react-icons/vsc";
import { Link } from "react-router-dom";

const services = [
  {
    title: "Breakfast",
    content: "The hotel serves breakfast from 06.00 a.m. to 10.00 a.m.",
  },
  {
    title: "Banking & Credit Facilities",
    content:
      "All Thai major credit cards are accepted. All hotel services can be charged back to room accounts. Charges should be paid upon check out.",
  },
  {
    title: "Baby Sitting",
    content: "No baby sitter services.",
  },
  {
    title: "Check-In & Check-Out Times",
    content: [
      "Check-in time is from 2.00 p.m.",
      "Check-out time is by 12.00 p.m. (noon)",
      "Reception is open 24 hours.",
    ],
  },
  {
    title: "Conference Facilities",
    content:
      "Our facilities are able either cater for meetings, receptions, dinner and cocktail functions in a traditional Thai setting. For more information, please contact Sales Office Please dial “704” or call 088-6961451.",
  },
  {
    title: "Direct Dial Phone",
    content: "For more information, please contact front desk",
  },
  {
    title: "Door Lock",
    content: [
      {
        title: "Garden Wing",
        content: "Please do lock the door when you leave your room.",
      },
      {
        title: "Tower Wing",
        content:
          "Please do not press the door lock when you leave your room it will be locked automatically. Do not lock the door while you are going out , The door will close automatically",
      },
    ],
  },
  {
    title: "Drinking Water",
    content:
      "The Hotel provides free 2 glass bottles of drinking water per day.",
  },
  {
    title: "Electric System",
    content:
      "Please insert key tag to turn on the light and take off key tag while you leave out.",
  },
  {
    title: "Fire and Emergency Procedures",
    content: [
      "Holiday Garden Hotel & Resort Chiang Mai is equipped with sophisticated fire prevention systems. Every guest room is equipped with a heat detector. Please study the escape plan affixed to the back of your room door. In the event of fire, collect your room keytag and leave the room immediately, closing your door behind you.",
      {
        title: "Procedure:",
        content: [
          "Alert Hotel staff or call the operator by pressing 0",
          "Be prepared to evacuate the hotel. Do not bring luggage with you",
          "You will be contacted by the Hotel Operator if there is a fire elsewhere outside your room, shut the room door, place wet towels under it and wait for assistance",
        ],
      },
    ],
  },
  {
    title: "Free WIFI  internet",
    content:
      "The hotel offers high-speed internet access within the hotel. Username and password are posted on the mirror of the room.",
  },
  {
    title: "Private Transfer",
    content: "Taxi service for reservation, please contact front desk.",
  },
  {
    title: "Restaurant & Coffee Shop",
    content: [
      {
        title: "Huay Kaew Coffee Shop",
        content:
          "Huay Kaew offers an extensive variety of authentic Thai and International cuisine. Huay Kaew is open from 06.00 a.m. to 04.00 p.m.",
      },
      {
        title: "Mae Sa Restaurant",
        content:
          "Mae Sa Restaurant is server you a daily buffet lunch at 11.00 a.m. to 2.00 p.m.",
      },
      {
        title: "Lush Café and Garden",
        content:
          "Located in the garden, the Pool Bar is where to go if you want to relax. Relax under the shade of trees for peace and comfort. suitable for relaxation. Open daily from 08.00 a.m. to 05.00 p.m.",
      },
      {
        title: "Room Service",
        content: "Operating from 07.00 a.m. to 04.00 p.m. Please dial “61”.",
      },
      {
        title: "International Buffet Lunch",
        content: [
          "Daily buffet lunch at 11.00 a.m. to 2.00 p.m.",
          "For reservation, Please dial “61”.",
        ],
      },
    ],
  },
];

const nearbyPlaces = [
  { title: "Kad Rim Kam", distance: "100 meters" },
  { title: "Maya shopping Mall", distance: "300 meters" },
  { title: "One Nimman", distance: "400 meters" },
  { title: "Chiang Mai University", distance: "800 meters" },
  { title: "Chiang Mai Zoo", distance: "1.5 kilometers" },
  {
    title: "Chiang Mai International Convention and Exhibition Center",
    distance: "5 kilometers",
  },
  { title: "Chiang Mai Airport", distance: "5 kilometers" },
  { title: "Chiang Mai Railway Station", distance: "6.5 kilometers" },
  { title: "Royal Park Rajapruek", distance: "8 kilometers" },
];

const roomItems = [
  { title: "Glass doors/mirror", price: 2500 },
  { title: "Electric kettle", price: 500 },
  { title: "Security safety box / key", price: 3500 },
  { title: "Key + Tag", price: 3500 },
  { title: "Chair", price: 2500 },
  { title: "Drinking Glass", price: 50 },
  { title: "Empty Drinking Bottle", price: 40 },
  { title: "Shampoo/shower bottle", price: 100 },
  { title: "Glass tray", price: 50 },
  { title: "Amenities tray", price: 100 },
  { title: "Ceramic tea & coffee set", price: 100 },
  { title: "Hair dryer", price: 1000 },
  { title: "Wicker basket", price: 100 },
  { title: "Refrigerator", price: 6000 },
  { title: "Bed", price: 2000 },
  { title: "Dressing table", price: 3000 },
  { title: "Luggage desk", price: 1500 },
  { title: "Bin", price: 100 },
  { title: "Mattress (6 feet)", price: 5000 },
  { title: "Mattress (3.5 feet)", price: 4000 },
  { title: "Mattress (3 feet)", price: 4000 },
  { title: "Bottle Opener", price: 100 },
  { title: "Television", price: 15000 },
  { title: "Television remote", price: 500 },
  { title: "Telephone", price: 500 },
  { title: "Pillow case", price: 100 },
  { title: "Mattress protector", price: 500 },
  { title: "Bath towel", price: 250 },
  { title: "Bath mat", price: 150 },
  { title: "Hand towel", price: 150 },
  { title: "Duvet (small)", price: 1000 },
  { title: "Duvet (big)", price: 1500 },
  { title: "Bet sheet", price: 500 },
  { title: "Bath curtain", price: 500 },
  { title: "Wooden floor (inch2)", price: 2000 },
  { title: "Hanger", price: 50 },
  { title: "Slipper", price: 100 },
  { title: "Pillow", price: 200 },
  { title: "Bed skirt", price: 1000 },
  { title: "Pillow slip", price: 100 },
  { title: "Duvet filling", price: 1500 },
  { title: "Table top", price: 2000 },
  { title: "Power plugs", price: 800 },
  { title: "Wall re-paint", price: 2000 },
  { title: "Ceramic ware (small)", price: 50 },
  { title: "Ceramic ware (big)", price: 100 },
  { title: "Pool bed/sunbed", price: 15000 },
  { title: "Table linen/table cloth", price: 500 },
  { title: "Table", price: 2500 },
  { title: "Meeting chair", price: 1000 },
  { title: "Chair cloth cover", price: 500 },
  { title: "Granite table", price: 3500 },
  { title: "Change water pool", price: 15000 },
  { title: "Urine/alcohol spillage on bed", price: 2000 },
];

// const items = [
//   { title: "Razor", price: 20 },
//   { title: "Toothbrush", price: 10 },
//   { title: "Toothpaste", price: 20 },
//   { title: "Spoon & fork (per set)", price: 5 },
//   { title: "Plastic bowl", price: 5 },
//   { title: "Sanitary pad", price: 20 },
// ];

const renderContent = content => {
  if (typeof content === "string")
    return <p className="mt-1 ml-3">{content}</p>;

  if (content.title && content.content) {
    return (
      <div className="mt-1 ml-3">
        <h3 className="font-medium">{content.title}</h3>
        {renderContent(content.content)}
      </div>
    );
  }

  if (Array.isArray(content)) {
    return (
      <ul>
        {content.map((item, index) => (
          <li key={index}>{renderContent(item)}</li>
        ))}
      </ul>
    );
  }
};

const HotelDirectoryPage = () => {
  return (
    <Layout title="Hotel Directory">
      <div className="px-4 pb-8 max-w-3xl mx-auto bg-[#fafafa] ">
        {/* Apply to all pages */}
        <div className="text-sm py-3 text-neutral-600 flex gap-1 items-center">
          <Link to="/">
            <p className="hover:underline hover:text-deepOrange-700">Home</p>
          </Link>
          <VscChevronRight />
          <p className="font-medium">Hotel Directory</p>
        </div>
        {/* Ends here */}
        <section className="mt-5">
          <h1 className="text-center text-xl font-medium text-neutral-600">
            Guest Services
          </h1>
          <div className="mt-4 flex flex-col gap-5 text-neutral-600">
            {services.map((service, index) => (
              <div key={index} className="text-sm">
                <h2 className="text-deepOrange-700 font-medium">
                  {service.title}
                </h2>
                {renderContent(service.content)}
              </div>
            ))}
          </div>
        </section>
        {/* Mini store */}
        {/* <section className="mt-7">
          <h2 className="text-center text-xl font-medium text-neutral-600">
            Mini Store
          </h2>
          <table className="mt-3 w-full text-sm">
            <thead>
              <tr className="bg-neutral-200">
                <th className="text-deepOrange-700 font-medium text-left p-2">
                  Item
                </th>
                <th className="text-sm text-deepOrange-700 font-medium text-right p-2">
                  Price (in Thai Baht)
                </th>
              </tr>
            </thead>
            <tbody className="text-neutral-600">
              {items.map((item, index) => (
                <tr
                  key={item.title}
                  className={`${index % 2 === 0 ? "" : "bg-neutral-200"}`}
                >
                  <td className="p-2">{item.title}</td>
                  <td className="text-right p-2">{item.price}.-</td>
                </tr>
              ))}
            </tbody>
          </table>
          <p className="mt-2 text-deepOrange-700 text-sm">
            * Please contact the front office to purchase these items.
          </p>
        </section> */}
        <section className="mt-8">
          <h2 className="text-center text-xl font-medium text-neutral-600">
            Nearby Places
          </h2>
          <p className="mt-2 text-neutral-600 text-sm">
            We are located near many tourist attractions. And within walking
            distance of shopping and dining options.
          </p>
          <table className="mt-4 w-full">
            <thead>
              <tr className="bg-neutral-200">
                <th className="text-sm text-deepOrange-700 font-medium text-left p-2">
                  Attractions
                </th>
                <th className="text-sm text-deepOrange-700 font-medium text-left w-1/2">
                  Distance
                </th>
              </tr>
            </thead>
            <tbody className="text-sm text-neutral-600">
              {nearbyPlaces.map((place, index) => (
                <tr
                  key={place.title}
                  className={`${index % 2 === 0 ? "" : "bg-neutral-200"}`}
                >
                  <td className="p-2">{place.title}</td>
                  <td>{place.distance}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </section>
        <section className="mt-8">
          <h2 className="text-center text-xl font-medium text-neutral-600">
            Safety and Important Notices
          </h2>
          <ol className="mt-2 ml-6 text-neutral-600 text-sm list-decimal">
            <li>
              The following articles, which may be a nuisance to other guests,
              cannot be brought into the hotel:
              <ul className="ml-3 list-disc">
                <li className="mt-1">Animals and birds</li>
                <li className="mt-1">
                  Articles which produce disturbing noises
                </li>
                <li className="mt-1">
                  Explosive or flammable articles such as gunpowder and gasoline
                </li>
              </ul>
            </li>
            <li>
              Creating a disturbance in the hotel which annoys other guest is
              not permitted.
            </li>
            <li>Gambling is prohibited within the hotel premises.</li>
            <li>Only registered persons are allowed to use the guest rooms.</li>
            <li>
              Distributing advertising material within the hotel is not
              permitted.
            </li>
            <li>
              It is prohibited to take photographs for commercial purposes
              within the hotel premises without prior approval.
            </li>
          </ol>
        </section>
        <section className="mt-8">
          <h2 className="text-center text-xl font-medium text-neutral-600">
            Fine when the customer is doing damage to return to its original
            state.
          </h2>
          <table className="mt-4 w-full">
            <thead>
              <tr className="bg-neutral-200">
                <th className="text-sm text-deepOrange-700 font-medium text-left p-2">
                  No
                </th>
                <th className="text-sm text-deepOrange-700 font-medium text-left">
                  Item
                </th>
                <th className="text-sm text-deepOrange-700 font-medium text-left">
                  <span>Fee</span>
                  <span className="ml-1 text-neutral-500 text-xs font-normal">
                    (in Thai Baht)
                  </span>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm text-neutral-600">
              {roomItems.map((item, index) => (
                <tr
                  key={item.title}
                  className={`${index % 2 === 0 ? "" : "bg-neutral-200"}`}
                >
                  <td className="p-2">{index + 1}</td>
                  <td>{item.title}</td>
                  <td>{item.price.toLocaleString()}.-</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-6 text-sm text-neutral-600">
            <h4 className="font-medium">Notice:</h4>
            <ol className="ml-6 mt-2 list-decimal">
              <li>
                The hotel will be held responsible for the guest&apos;s property
                in case of loss or damage as following:
                <ol className="mt-1">
                  <li className="before:content-['1.1.'] before:mr-2">
                    The loss or damage occurs in the hotel.
                  </li>
                  <li className="before:content-['1.2.'] before:mr-2">
                    If the property that is lost or damaged following article
                    1.1 is money, gold, traveler cheques, jewelry of other
                    valuable items the Hotel shall take responsibility not over
                    5,000 Thai baht unless the guest declares and deposits
                    his/her property with the Hotel.
                  </li>
                </ol>
              </li>
              <li className="mt-2">
                The hotel shall not be liable for any loss or damage by the
                following cases:
                <ol className="mt-1">
                  <li className="before:content-['2.1.'] before:mr-2">
                    The case is beyond the control of the Hotel.
                  </li>
                  <li className="before:content-['2.2.'] before:mr-2">
                    The existing condition of the article.
                  </li>
                  <li className="before:content-['2.3.'] before:mr-2">
                    The loss or damage is made by the guest or the visitor.
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default HotelDirectoryPage;
