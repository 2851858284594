// React-router-dom
import { Link } from "react-router-dom";

// Components
import Layout from "../components/Layout";
import Button from "../components/shared/Button";
import Card from "../components/home/Card";
import Carousel from "../components/home/Carousel";

// Icons
// import { VscFeedback } from "react-icons/vsc";
import {
  PiCallBellLight,
  PiBuildingsLight,
  PiBookOpenTextLight,
  PiBagLight,
  // PiFileTextLight,
  // PiPencilSimpleLineThin,
} from "react-icons/pi";

const slides = [
  {
    title: "Breakfast Buffet",
    description:
      "Breakfast starts at 6 a.m. to 10 a.m. Don't forget to bring your breakfast coupons and present it to our staff.",
    imageUrl: "/images/home/abf.jpg",
  },
  {
    title: "Swimming Pool",
    description:
      "Take a refreshing dip or simply lounge by our inviting swimming pool, nestled in the serene garden area.",
    btn: "Our Facilities",
    btnLink: "/facilities",
    imageUrl: "/images/facilities/swimming-pool.jpg",
  },
  {
    title: "Conference Rooms",
    description:
      "Contact us today to book your ideal event space and turn your vision into reality.",
    btn: "Learn more",
    btnLink: "https://holidaygardenhotel.com/conference",
    imageUrl: "/images/home/conference.jpg",
  },
];

const currentYear = new Date().getFullYear();

const HomePage = () => {
  return (
    <Layout>
      <div className="container max-w-6xl mx-auto p-3 min-h-[calc(100vh-64px)] flex flex-col justify-between gap-4">
        <div className="flex flex-col gap-8">
          <div className="mt-4 flex flex-col gap-5 lg:flex-col-reverse lg:gap-12">
            <div className="lg:text-center lg:flex lg:flex-col lg:items-center">
              <span className="text-deepOrange-700 font-medium text-sm">
                Dear
              </span>
              <h1 className="mt-[3px] font-bold text-2xl text-neutral-700">
                Valued Guest,
              </h1>
              <p className="mt-1 text-neutral-600 max-w-lg">
                Welcome to Chiang Mai! Whether you're here for business or
                leisure, we're honored to have you.
              </p>
            </div>

            <div className="flex items-center justify-center aspect-[11/7] sm:aspect-video xl:max-h-[82vh] max-w-full">
              <Carousel>
                {slides.map((slide, index) => (
                  <Card key={index} content={slide} />
                ))}
              </Carousel>
            </div>
          </div>

          <div className="flex flex-col gap-4 mx-auto xl:max-w-2xl">
            <div className="xl:text-center">
              <span className="text-deepOrange-700 font-medium text-sm xl:text-center">
                Discover
              </span>
              <p className="mt-1 text-neutral-600">
                Uncover details about our facilities, services, and other
                essentials to optimize your trip.
              </p>
            </div>

            <div className="w-full grid grid-cols-2 gap-2">
              <Link to="/hotel-directory" className="w-full">
                <Button
                  variant="contained"
                  expand
                  icon={<PiBookOpenTextLight />}
                >
                  Hotel Directory
                </Button>
              </Link>
              <Link to="/room-service" className="w-full">
                <Button variant="contained" expand icon={<PiCallBellLight />}>
                  Room Service
                </Button>
              </Link>
              <Link to="/facilities" className="w-full">
                <Button variant="contained" expand icon={<PiBuildingsLight />}>
                  Facilities
                </Button>
              </Link>

              <Link to="/mini-store" className="w-full">
                <Button variant="contained" expand icon={<PiBagLight />}>
                  Mini Store
                </Button>
              </Link>
              {/* <Link to="/guest-questionnaire" className="w-full col-span-2">
                <Button
                  variant="outlined"
                  expand
                  icon={<PiPencilSimpleLineThin />}
                >
                  Guest Questionnaire
                </Button>
              </Link> */}
            </div>
          </div>
        </div>
        <p className="text-center text-neutral-400 text-sm max-w-xl mx-auto lg:mt-6">
          © {currentYear} Holiday Garden Hotel & Resort. All rights reserved.
        </p>
      </div>
    </Layout>
  );
};

export default HomePage;
